import { Button } from '@material-ui/core'
import type { User } from '@willig/types/api'
import { add, differenceInCalendarDays } from 'date-fns'
import { useFormContext, useWatch } from 'react-hook-form'
import { Picker } from 'src/components/Form'
import { Stack } from 'src/components/UI/Stack'
import { useGetList } from 'src/libs/useGetList'

import { DatePicker } from '../../../components/Form/index'
import { SinaoStatusEnum } from '../../../types/api/extendedTypes'

export function ParticularFilters(props: { onChange: any }) {
  const { onChange } = props

  const { data: sweeps } = useGetList<User>('User', {
    filter: { '<access_level': [50], '>access_level': [9] },
    sort: { field: 'login', order: 'ASC' },
  })

  const { setValue } = useFormContext()

  const startDate = useWatch({ name: 'startDate' })
  const endDate = useWatch({ name: 'endDate' })

  return (
    <Stack
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 8,
      }}
    >
      <Stack
        css={{
          display: 'flex',
          width: '100%',
          gap: 8,
        }}
      >
        <Picker
          options={
            sweeps.map((sweep) => {
              return {
                value: sweep.login,
                label: sweep.login,
              }
            }) ?? []
          }
          name="ramoneur"
          label="Ramoneur"
          onChange={() => onChange()()}
        />
        <Picker
          options={[
            {
              value: 'especes',
              label: 'Espèce',
            },
            {
              value: 'cheque',
              label: 'Chèque',
            },
            {
              value: 'CB',
              label: 'Carte bancaire',
            },
            {
              value: 'impayé',
              label: 'Sans paiement',
            },
            {
              value: 'payé',
              label: 'Avec paiement',
            },
          ]}
          name="type_paiement"
          label="Paiement"
          onChange={() => onChange()()}
        />

        <DatePicker
          name="startDate"
          label="Début de période"
          onChange={(date) => {
            const diff = endDate
              ? differenceInCalendarDays(new Date(endDate), date)
              : undefined
            if (diff && diff <= 0) {
              setValue('endDate', date)
            }
            onChange()()
          }}
        />
        <DatePicker
          name="endDate"
          label="Fin de période"
          onChange={(date) => {
            const diff = startDate
              ? differenceInCalendarDays(date, new Date(startDate))
              : undefined
            if (diff && diff <= 0) {
              setValue('startDate', date)
            }
            onChange()()
          }}
        />
        <Picker
          options={[
            { value: SinaoStatusEnum.notBilled, label: 'Non facturé' },
            { value: SinaoStatusEnum.draft, label: 'Brouillon' },
            { value: SinaoStatusEnum.paid, label: 'Payée' },
            { value: SinaoStatusEnum.final, label: 'Facturée' },
            { value: SinaoStatusEnum.billed, label: 'Envoyé en facturation' },
          ]}
          name="sinaoStatus"
          label="Status Sinao"
          onChange={() => onChange()()}
        />
      </Stack>
      <Stack
        css={{
          display: 'flex',
          width: '100%',
          gap: 8,
        }}
      >
        <PresetsFilters onChange={onChange} />
      </Stack>
    </Stack>
  )
}

type PresetsFiltersProps = {
  onChange: any
}

function PresetsFilters(props: PresetsFiltersProps) {
  const { onChange } = props
  const { setValue } = useFormContext()

  const today = new Date()
  const yesterDay = add(today, { days: -1 })

  return (
    <Stack
      css={{
        display: 'flex',
        width: '100%',
        gap: 8,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setValue('startDate', yesterDay)
          setValue('endDate', yesterDay)
          onChange()()
        }}
      >
        Hier
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          setValue('startDate', today)
          setValue('endDate', today)

          onChange()()
        }}
      >
        Aujourd'hui
      </Button>
    </Stack>
  )
}
