import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import { Stack } from '../../../components/UI/Stack'
import { money } from '../../../libs/money'

type FooterProps = {
  total: number
  totalPage: number
  label: string
}

type TotalFooterProps = {
  htValues: FooterProps
  ttcValues: FooterProps
}

export function TotalFooter(props: TotalFooterProps) {
  const { htValues, ttcValues } = props

  const theme = useTheme()

  return (
    <Stack
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(2),
      }}
    >
      <TotalFooterImplementation {...ttcValues} />
      <TotalFooterImplementation {...htValues} variant="ht" />
    </Stack>
  )
}

function TotalFooterImplementation(props: FooterProps & { variant?: 'ht' }) {
  const { total, totalPage, label, variant } = props

  const theme = useTheme()

  const bgColor = variant
    ? theme.palette.grey[500]
    : theme.palette.primary.light

  if (total === 0) return null

  return (
    <Stack
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: bgColor,
        color: 'white',
      }}
    >
      <>
        <Typography variant={'h5'}>{label}</Typography>
        <Stack
          css={{
            // width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing(2),
          }}
        >
          <Typography variant={'h5'}>{money(totalPage)}</Typography>
          <Typography variant={'h5'}>/</Typography>
          <Typography variant={'h5'}>{money(total)}</Typography>
        </Stack>
      </>
    </Stack>
  )
}
