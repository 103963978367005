import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import { colors } from '@willig/design/tokens'
import classNames from 'classnames'
import { format } from 'date-fns'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { Confirm } from 'react-admin'
import { useMutation } from 'react-query'
import { useInvoiceQueryBuilder } from 'src/libs/invoiceQueryBuilder'
import type {
  DailyInvoices,
  SinaoStatusEnum,
} from 'src/types/api/extendedTypes'

import { money } from '../../../libs/money'
import type {
  InvalidReasons,
  InvoiceSelectedState,
  InvoicesSelectedState,
} from '../index'
import { AdressName } from '../index'
import { InvalidReasonMap } from '../types'

const SinaoStatusToLabelMap: Record<SinaoStatusEnum, string> = {
  notBilled: 'Non facturé',
  draft: 'Brouillon',
  final: 'Final',
  paid: 'Payé',
  billed: 'Facture',
}

const useRowStyles = makeStyles(function (theme) {
  return {
    header: {},
    cell: {
      padding: theme.spacing(0),
    },
    error: {
      backgroundColor: colors.grayLight,
    },
  }
})

type RafRowProps = {
  dailyInvoice: DailyInvoices
  addressesSelectedStateStore: ProfunctorState<InvoicesSelectedState>
  isPro: boolean
  invalidReasons: InvalidReasons
}
export function InvoiceRow(props: RafRowProps) {
  const { dailyInvoice, isPro, addressesSelectedStateStore, invalidReasons } =
    props
  const {
    adresses_interventions,
    ramoneur,
    type_paiement,
    price_ttc,
    sinao_id,
    date_intervention,
    infos_contacts,
    sinao_invoice_status,
  } = dailyInvoice

  const { state, setState } = addressesSelectedStateStore.promap<
    InvoiceSelectedState | undefined
  >({
    get: function get(stateValue) {
      return stateValue[dailyInvoice.intervention_report_id!]
    },
    set: function set(invoiceId, prevState) {
      return {
        ...prevState,
        [dailyInvoice.intervention_report_id!]: invoiceId!,
      }
    },
  })

  function onChange() {
    setState((prevState) => {
      return {
        ...prevState,
        invoice: dailyInvoice,
        selected: !prevState?.selected,
      }
    })
  }

  const styles = useRowStyles()

  const fullName = `${infos_contacts?.nom} ${infos_contacts?.prenom}`

  const roundTtc = price_ttc ? money(price_ttc) : undefined

  const roundHt = getRoundHt(dailyInvoice)

  const hasUniqSinaoInvoiceError = Boolean(
    invalidReasons?.reasons.length &&
      invalidReasons?.reasons.length === 1 &&
      invalidReasons.reasons.includes('hasSinaoInvoice'),
  )

  const errorStyle =
    invalidReasons.invalid &&
    invalidReasons?.reasons.length &&
    !hasUniqSinaoInvoiceError &&
    styles.error

  if (isPro) {
    return (
      <ToolTipRow invalidReasons={invalidReasons}>
        <TableRow className={classNames([styles.header, errorStyle])}>
          <Cell>
            <Checkbox
              checked={state?.selected ?? false}
              onChange={onChange}
              disabled={invalidReasons.invalid}
            />
          </Cell>
          <SinaoId dailyInvoice={dailyInvoice} />
          <Cell>
            <AdressName id={adresses_interventions?.adresse_id} />
          </Cell>
          <DateCell date={date_intervention} />
          <DateCell date={date_intervention} />
          <CellPrice color={roundHt.color} price={roundHt.price} />
          <CellPrice price={roundTtc} />
          <Cell>
            <RevertButton
              sinaoId={sinao_id}
              sinaoStatus={sinao_invoice_status}
              isPro
            />
          </Cell>
        </TableRow>
      </ToolTipRow>
    )
  }
  return (
    <ToolTipRow invalidReasons={invalidReasons}>
      <TableRow className={classNames([styles.header, errorStyle])}>
        <Cell>
          <Checkbox
            checked={state?.selected ?? false}
            onChange={onChange}
            disabled={invalidReasons.invalid}
          />
        </Cell>
        <Cell>{fullName}</Cell>
        <SinaoId dailyInvoice={dailyInvoice} />
        <Cell>
          <AdressName id={adresses_interventions?.adresse_id} />
        </Cell>
        <DateCell date={date_intervention} />
        <Cell>{ramoneur}</Cell>
        <Cell>{type_paiement}</Cell>
        <CellPrice color={roundHt.color} price={roundHt.price} />
        <CellPrice price={roundTtc} />
        <Cell>
          <RevertButton sinaoId={sinao_id} sinaoStatus={sinao_invoice_status} />
        </Cell>
      </TableRow>
    </ToolTipRow>
  )
}

function DateCell(props: { date?: string }) {
  const { date } = props
  if (!date) return <Cell></Cell>
  const formattedDate = format(new Date(date), 'dd/MM/yyyy')
  return <Cell>{formattedDate}</Cell>
}

function ToolTipRow(
  props: PropsWithChildren<{ invalidReasons: InvalidReasons }>,
) {
  const { invalidReasons, children } = props
  const translatedReasons = invalidReasons.reasons.map((reason) => {
    return InvalidReasonMap[reason]
  })
  return (
    <>
      <Tooltip
        title={
          invalidReasons?.reasons.length ? (
            <div>
              <h4 style={{ margin: 0, fontWeight: 'bold' }}>
                {translatedReasons.join(' - ')}
              </h4>
            </div>
          ) : (
            ''
          )
        }
      >
        {children as any}
      </Tooltip>
    </>
  )
}

export function getRoundHt(dailyInvoice: DailyInvoices): CellPriceProps {
  const { invoice } = dailyInvoice

  if (!invoice) {
    return {
      price: undefined,
      color: 'inherit',
    }
  }

  const {
    price_edited,
    price_computed,
    tva,
    vatFreeTotalPrice,
    vatFreeDiscount,
  } = invoice

  if (vatFreeDiscount && vatFreeDiscount > 0) {
    return {
      price: money(vatFreeTotalPrice ?? 0),
      rawPrice: vatFreeTotalPrice,
      color: 'primary',
    }
  }

  if (price_edited && tva) {
    return {
      price: money((100 * price_edited) / (100 + tva)),
      rawPrice: (100 * price_edited) / (100 + tva),
      color: 'error',
    }
  }
  if (price_computed) {
    return {
      price: money(price_computed),
      rawPrice: price_computed,
      color: 'inherit',
    }
  }
  if (vatFreeTotalPrice) {
    return {
      price: money(vatFreeTotalPrice),
      rawPrice: vatFreeTotalPrice,
      color: 'inherit',
    }
  }
  return {
    price: undefined,
    rawPrice: undefined,
    color: 'inherit',
  }
}

type CellPriceProps = {
  price?: string | number
  rawPrice?: number
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
}

function CellPrice(props: CellPriceProps) {
  const { price, color = 'inherit' } = props

  if (!price) {
    return <Cell></Cell>
  }

  return (
    <Cell>
      <Typography color={color}>{price}</Typography>
    </Cell>
  )
}

function Cell(props: PropsWithChildren<{}>) {
  const styles = useRowStyles()

  return <TableCell className={styles.cell}>{props.children}</TableCell>
}

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      padding: 16,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: 4,
      color: theme.palette.common.white,
      maxWidth: 'fit-content',
      fontWeight: 'bold',
    },
  }
})

type SinaoIdProps = {
  dailyInvoice: DailyInvoices
}

function SinaoId(props: SinaoIdProps) {
  const { dailyInvoice } = props

  const {
    sinao_id,
    sinao_invoice_status,
    sinao_invoice_reference,
    intervention_report_id,
  } = dailyInvoice
  const theme = useTheme()
  const backgroundColor = getSinaoBackgroundColor(theme, sinao_invoice_status)
  const { root } = useStyles()

  const invoiceQueryBuilder = useInvoiceQueryBuilder()
  const { mutateAsync } = useMutation(invoiceQueryBuilder.getInvoicePdf())

  if (!sinao_id) return <TableCell />
  const label =
    sinao_invoice_reference ??
    getSinaoStatusLabel(sinao_id, sinao_invoice_status)
  return (
    <TableCell>
      {label && (
        <Box
          className={root}
          style={{
            backgroundColor: backgroundColor,
            cursor: intervention_report_id ? 'pointer' : 'default',
          }}
          onClick={async () => {
            if (intervention_report_id) {
              const fileURL = await mutateAsync(intervention_report_id)
              window.open(fileURL, '_blank')
            }
          }}
        >
          {label}
        </Box>
      )}
    </TableCell>
  )
}

function getSinaoStatusLabel(id: string, status?: SinaoStatusEnum) {
  if (!status) return undefined
  const label = SinaoStatusToLabelMap[status]

  return `${label} ${id}`
}

type RevertButtonProps = {
  sinaoId?: string
  sinaoStatus?: SinaoStatusEnum
  isPro?: Boolean
}

function RevertButton(props: RevertButtonProps) {
  const { sinaoId, isPro = false, sinaoStatus } = props

  const invoiceQueryBuilder = useInvoiceQueryBuilder()
  const { mutateAsync } = useMutation(invoiceQueryBuilder.revertInvoice())
  const [open, setOpen] = useState(false)

  const content = getContent(isPro)

  if (!sinaoId || !sinaoStatus || sinaoStatus !== 'draft') return <></>
  return (
    <>
      <Confirm
        isOpen={open}
        title={`Annuler une facture`}
        content={content}
        onConfirm={() => {
          mutateAsync(sinaoId, { onSuccess: () => setOpen(false) })
        }}
        onClose={() => setOpen(false)}
      />
      <Button
        variant="text"
        color="secondary"
        size="small"
        onClick={() => setOpen(true)}
      >
        Annuler
      </Button>
    </>
  )
}

function getContent(isPro: Boolean) {
  if (isPro)
    return 'Ce brouillon peut éventuellement regrouper plusieurs interventions ! Si tel est le cas, supprimer ce brouillon supprimera également le lien pour les autres interventions du groupement'
  return "Cette facture est sur le point d'être annulée"
}

function getSinaoBackgroundColor(theme: Theme, status?: SinaoStatusEnum) {
  switch (status) {
    case 'draft':
      return theme.palette.grey[500]
    case 'paid':
      return theme.palette.primary.light
    case 'final':
      return theme.palette.warning.main
    default:
      return theme.palette.grey[500]
  }
}
